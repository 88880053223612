export default {
    "table": {
        "no_rows": "Деректер жоқ",
        "settings": "Кесте параметрлері",
        "columns": "Бағандар",
        "fixed": "Тұрақты",
        "all": "Барлығы",
        "default": "Әдепкі",
        "confirm": "Растау",
        "getDataError": "Деректерді алу мүмкін болмады",
        "size": "Өлшем",
        "small": "Кіші",
        "middle": "Орташа",
        "large": "Үлкен"
    }
}